/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { TriangleDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import { LangStyle } from 'constants/index';

/* Services */

/**
 * Renders the Breadcrum component
 *
 * @param {string} page page name
 * @param {string} alsoin languages
 * @param {string} region country
 * @param {string} lang language
 * @returns {ReactElement} Breadcrum component
 */

const Breadcrum = ({ page, alsoin, url, region, lang, pageConfig, secNav }) => {
  const pageConfigObj = pageConfig;

  const displayAlsoIn = () => {
    if (alsoin) {
      return alsoin.map((l, index) => {
        return index <= 1 ? (
          <NextLink href={l.url} passHref prefetch={false} legacyBehavior>
            <Link>
              <Box
                key={index}
                //href={l.url}
                color="#cf4520"
                ml="5px"
                fontFamily="FedraSerifAStdBook"
                pr={{ base: '10px', lg: '10px' }}
                fontSize={{ base: '16px', lg: '13px' }}
                borderRight={
                  index + 1 < alsoin.length ? '1px solid rgb(163 157 147)' : ''
                }
                _hover={{ textDecoration: 'none' }}
                cursor="pointer"
              >
                {l.title}
              </Box>
            </Link>
          </NextLink>
        ) : (
          <>
            <Menu>
              {index === 2 && (
                <MenuButton
                  color="#cf4520"
                  ml="5px"
                  pr={{ base: '4px', lg: '10px' }}
                  fontSize={{ base: '16px', lg: '13px' }}
                >
                  More <TriangleDownIcon fontSize="9px" />
                </MenuButton>
              )}
              <MenuList
                color="#cf4520"
                w="100px"
                minW="100px"
                border="0"
                key={nanoid()}
              >
                {alsoin.map((n, i) => {
                  return i > 1 && (
                    <NextLink href={n.url} passHref prefetch={false} legacyBehavior>
                      <Link>
                        <MenuItem
                          w="100px"
                          bgColor="transparent"
                          _focus={{ bgColor: 'trasparent' }}
                          _hover={{ bgColor: '#e4ded4' }}
                        >
                          <Box
                            key={i}
                            color="#cf4520"
                            fontSize={{ base: '12px', lg: '13px' }}
                            _hover={{ textDecoration: 'none' }}
                            cursor="pointer"
                          >
                            {n.title}
                          </Box>
                        </MenuItem>
                      </Link>
                    </NextLink>
                  );
                })}
              </MenuList>
            </Menu>
          </>
        );
      });
    }
    return null;
  };

  return <>
    <Box
      zIndex="6"
      display={{
        base: alsoin?.length > 0 ? 'flex' : 'none',
        sm: alsoin?.length > 0 ? 'flex' : 'none',
        md: 'block',
      }}
      fontFamily="Fedra-Light"
      fontSize="13px"
      letterSpacing=".2px"
      color="rgb(0 0 0)"
      pos="relative"
      w="100%"
      background="rgba(250,247,240,.6)"
      height="40px"
      // maxHeight="40px"
      // overflow="hidden"
      justify-content="flex-end"
      p={{
        base: '9px 15px',
        sm: '9px 15px',
        lg: '9px calc((100% - 1134px) / 2) 9px calc((100% - 1134px) / 2)',
      }}
    >
      {/* <div className={classes["breadcum-block"]}>           */}
      <Box
        float={LangStyle[lang]?.alignRTL ? LangStyle[lang].alignRTL : 'left'}
      >
        <Breadcrumb
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          p={{ md: '0 0 0 10px', lg: '0 0 0 131px' }}
          fontWeight="medium"
          fontSize="0.8rem"
          display={{ base: 'none', lg: 'block', md: 'block' }}
        >
          {page?.map((data, index, { length }) => {
            const link = data.link;
            return data?.title && (
              <BreadcrumbItem key={index}>
                {index + 1 < length && (
                  <NextLink
                    href={link}
                    passHref
                    prefetch={false}
                    legacyBehavior
                  >
                    <BreadcrumbLink
                      _hover={{
                        color: 'orange.50',
                        textDecoration: 'underline',
                      }}
                      fontFamily="FedraSansStd-book"
                      p="0 2px"
                      fontSize="13px"
                      textTransform="capitalize"
                      pointerEvents="pointer"
                    // onClick={e => refreshPage(e?.target?.href)}
                    >
                      {data.title}
                    </BreadcrumbLink>
                  </NextLink>
                )}
                {index + 1 === length && (
                  <BreadcrumbLink
                    //href={index ? link : '/' + region + '/en'}
                    color="orange.50"
                    _hover={{ textDecoration: 'none' }}
                    fontFamily="FedraSansStd-book"
                    p="0 2px"
                    fontSize="13px"
                    textTransform="capitalize"
                    pointerEvents="none"
                  // onClick={e => refreshPage(e?.target?.href)}
                  >
                    {data.title}
                  </BreadcrumbLink>
                )}
              </BreadcrumbItem>
            );
          })}
          &nbsp;
        </Breadcrumb>
      </Box>
      {/* </div> */}
      {pageConfigObj?.alsoin !== 'hide' && !secNav && alsoin?.length > 0 && (
        <Box float="right" pr={{ base: '0', lg: '1%' }}>
          <Box
            float="left"
            as="span"
            display="block"
            fontFamily="FedraSansStd-book, sans-serif"
            fontSize={{ base: '16px', md: '13px' }}
            letterSpacing="0.2px"
            color="#aea493"
            position="relative"
            mr="6px"
          >
            Also in:
          </Box>
          <Box as="span" float="left" display="inline-flex">
            {displayAlsoIn()}
          </Box>
        </Box>
      )}
    </Box>
  </>;
};

export default Breadcrum;